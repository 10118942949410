
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerActiveLogList, exportBloggerActiveLogList } from "@/api/request/blogger";

//组件
@Component({
  name: "bloggerActiveLog",
  components: {
    Pagination,
  },
})

//代码
export default class extends Vue {
  //定义变量
  private total: number = 0; //总数
  private list: any[] = []; //列表数据
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = true; // 列表加载

  //请求参数
  private listQuery: any = {
    //页面数据
    row: 20,
    page: 1,

    //临时数据
    time: [],
    blogger_id_str: "",

    //请求数据
    country: "",
    nick_name: "", //博主昵称
    blogger_id: 0, //博主ID
    identity: -1, //博主身份
    start_date: "", //开始时间
    end_date: "", //结束时间
    blogger_identity: 0, //博主身份
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //用户类型
  private identityTypeList: any[] = [
    { value: -1, label: "全部用户" },
    { value: 0, label: "正常用户" },
    { value: 1, label: "内部人员" },
  ];

  //博主类型
  private bloggerTypeList: any[] = [
    { value: 0, label: "全部博主" },
    { value: 1, label: "自由博主" },
    { value: 2, label: "签约博主" },
  ];

  //创建时调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  ////获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_date = this.listQuery.time[0];
      this.listQuery.end_date = this.listQuery.time[1];
    } else {
      this.listQuery.start_date = "";
      this.listQuery.end_date = "";
    }
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerActiveLogList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //数据重置
    this.listQuery = {
      //页面数据
      row: 20,
      page: 1,

      //临时数据
      time: [],
      blogger_id_str: "",

      //请求数据
      country: "",
      nick_name: "", //博主昵称
      blogger_id: 0, //博主ID
      identity: -1, //博主身份
      start_date: "", //开始时间
      end_date: "", //结束时间
      blogger_identity: 0, //博主身份
    };

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //导出表格
  private async handleExcel() {
    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_date = this.listQuery.time[0];
      this.listQuery.end_date = this.listQuery.time[1];
    } else {
      this.listQuery.start_date = "";
      this.listQuery.end_date = "";
    }
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await exportBloggerActiveLogList(this.listQuery);

    //导出表格
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}博主活跃记录`) : saveAs(data, "博主活跃记录总览");
  }

  //获取时长文本
  private secondsToHMS(seconds: any): string {
    //数据赋值
    var remainingSeconds = seconds % 60;
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + remainingSeconds.toString().padStart(2, "0");

    //返回结果
    return formattedTime;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
